import React, { createRef, useRef, useState } from "react";
import { Link } from "gatsby"
import GoogleMap from "gatsby-theme-starberry-lomondgroup/src/components/map/google/nearby_places_map"

import "gatsby-theme-starberry-lomondgroup/src/components/MapInfo/Map.scss";

const Map = ({ property_data, setPlay, clickFloorplan }) => {

    const shareurl = typeof window !== 'undefined' ? window.location.href : ''

    const myRef = createRef();
    
    const[place_type, setPlaceType] = useState(null);

    // Get Map Details
    let mapItems = [];
    let mapItem = {};
    mapItem['lat'] = property_data.latitude
    mapItem['lng'] = property_data.longitude
    mapItem['id'] = property_data.id

    const mapMarkerImageShowFlag = 0;
    mapItems.push(mapItem);
    // Get Map Details

    var brochure_url = property_data?.selling_info?.publicBrochureUrl ? property_data?.selling_info?.publicBrochureUrl  : "";

    var home_report = property_data?.brochure?.length > 0 ? property_data?.brochure[0] : "";

    //console.log("home_report", property_data?.brochure)

    const trackerBrochure = (event) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Download '+event,
            'formType': event + ' - ' + shareurl,
            'formId': event,
            'formName': event,
            'formLabel': event
        });
    }

    let nearby_places = [
        {label:"Universities", value:"university"},
        {label:"Dentists", value:"dentist"},
        {label:"Train Stations", value:"train_station"},
        {label:"Cafes", value:"cafe"},
        {label:"Hospitals", value:"hospital"},
        {label:"Bars", value:"bar"},
        {label:"Libraries", value:"library"}
    ]

    const onChangePlace = (place) =>{
        setPlaceType(place);
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: "center" }); 
    }

    return (
        <React.Fragment>
            <section className="locarating-map">
                
                {(property_data?.video_tour?.url || property_data?.floorplan?.length > 0 || brochure_url || property_data?.epc?.length > 0) &&

                    <div className="more-info">
                        <div className="h4">More Information</div>
                        <div className="info-wrap">
                            {property_data?.video_tour && property_data?.video_tour?.url &&
                                <a href="javascript:;" className="icon-wrap" onClick={e => { setPlay(true) }}>
                                    <i className="camera"></i>
                                    <span className="text">Video Tour</span>
                                </a>
                            }

                            {property_data?.floorplan?.length > 0 &&
                                <a href="javascript:;" className="icon-wrap" onClick={() => clickFloorplan("floor_plan")}>
                                    <i className="floor"></i>
                                    <span className="text">Floorplans</span>
                                </a>
                            }
                            {brochure_url &&
                                <a href={brochure_url} onClick={() => trackerBrochure('Brochure')} className="icon-wrap" target={"_blank"}>
                                    <i className="brochure"></i>
                                    <span className="text">Brochure</span>
                                </a>
                            }

                            {property_data?.epc?.length > 0 &&
                                <a href="javascript:;" className="icon-wrap" onClick={() => clickFloorplan("epc")}>
                                    <i className="energy"></i>
                                    <span className="text">Energy Performance Certificate</span>
                                </a>
                            }

                            {home_report &&
                                <a href={home_report} onClick={() => trackerBrochure('Home Report')} className="icon-wrap" target={"_blank"}>
                                    <i className="home_icon"></i>
                                    <span className="text">Home Report</span>
                                </a>
                            }

                            {process.env.GATSBY_SITE_NAME === "Linley and Simpson" && 
                                (property_data?.search_type === "lettings" && 
                                <Link to={"/our-charges/"} className="icon-wrap">
                                    <i className="money_icon"></i>
                                    <span className="text">Fees & Charges</span>
                                </Link>)
                            }
                            
                        </div>
                    </div>
                }
                
                {(property_data.latitude && property_data.longitude) && 
                <>
                    <div ref={myRef}></div>
                    <GoogleMap lat={property_data.latitude} lng={property_data.longitude} height={"532px"} place_type={place_type}/>

                    <section className="link-group">
                        <h2>What’s nearby</h2>

                        <div className="what_we_are_link_row">
                            <div className="what_we_are_link">
                                <div className="link-block">
                                    <ul>
                                        {nearby_places.map((item, i) => {
                                            return(
                                                <li><a href="javascript:;" className={place_type === item.value ? "actv" : ""} onClick={() => onChangePlace(item.value)}>{item.label}</a></li>
                                            )
                                        })}

                                    </ul>

                                    <p>Please note that any places of interest are from Google Places, we can’t guarantee their accuracy or relevance, and BVStudents does not endorse them in any way.</p>
                                </div>
                            </div>
                        </div>

                    </section>
                </>
                }

            </section>
            

        </React.Fragment>
    );
};
export default Map;
